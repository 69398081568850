
// export const base_url = "http://api-cai-dev.spemai.com/api/";
// export const base_url = "http://api-cai-uat.spemai.com/api/";
// export const base_url = "http://api-cai-live.spemai.com/api/";
// dev
// export const base_url = "https://cai-core-gke-dev.spemai.com/api/";
// export const base_url_history = "https://spemai-cai-chat-history-gcp-dev.spemai.com/api/";
// dev
// export const base_url = "https://flameback-dev.spemai.com/api/"
// export const kb_base_url = "https://user-manage-gke-dev.spemai.com/"
// export const base_url_history = "https://phoenix-dev.spemai.com/api/";
//live
export const base_url = "https://flameback.spemai.com/api/"
export const kb_base_url = "https://user-manage.spemai.com/"
export const base_url_history = "https://phoenix.spemai.com/api/";
