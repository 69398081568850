import { ChatHistoryApiCall, apiCall, apiCall2 } from "../utils/api_util.service";

  
export const getDocumentSearch = async (doc_data:any) => {
    try {
      const data = await apiCall2('v1/document-search/search/','POST',doc_data);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }

  export const getDocumentHistory = async (session_id:any) => {
    try {
      const data = await ChatHistoryApiCall('v1/search-window/get/session/history?session_id='+session_id);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }  