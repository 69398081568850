import { useEffect, useState } from 'react';
import './history-right-bar.scss';
import { RightMenuProps } from '../../types/core';
import { convertUtcToSriLankanTime } from '../../utils/date_time';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { filterData, SearchType } from 'filter-data';
export const HistoryRightBar=({isExpanded,closeMenu,searchResults,selectValue}:RightMenuProps)=>{
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(new Date("2014/02/08"));
    const [endDate, setEndDate] = useState(new Date("2014/02/10"));
    const [history, setHistory] = useState(searchResults);
    const [search, setSearchValue] = useState('');

    const searchConditions = [
        {
          key: 'chat_body',
          value: search,
          type: SearchType.LK,
        }
      ];

      useEffect(()=>{
        setHistory(searchResults)
      },[searchResults])


      useEffect(()=>{
        if(search){
            setHistory(filterData(searchResults?searchResults:[], searchConditions))
        }else{
            setHistory(searchResults)
        }
       
    },[search])

    const popover = (
        <Popover  id="popover-basic" style={{maxWidth:'500px'}}>
          <Popover.Body >
            <p className='filter-heading'>Filter saved chats</p>
            <label className='filter-label pb-2'>Filter by Date Range</label>
            <div className='d-flex justify-content-between'>
                <DatePicker
        selected={startDate}
        onChange={(date:any) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        className={'date-class'}
      />
      <DatePicker
        selected={endDate}
        onChange={(date:any) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        className={'date-class'}
      />

            </div>

       
    
          </Popover.Body>
        </Popover>
      );

return (
<nav className={`history-sidebar ${isExpanded? 'history-sidebar-open' : ''}`}>

    <div className='chat-header d-flex align-items-center justify-content-between p-2'>
    <i onClick={closeMenu} className="bi bi-chevron-right me-2 menu-options-desktop"></i> <span className='history-heading'>Saved Chat History</span>
    
    {/* <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
    <span className='d-flex filter-txt pe-1 text-left' style={{cursor:'pointer'}}><i className="pe-1 bi bi-filter "></i><span className='hide-mobile'>Filter</span></span>
    </OverlayTrigger> */}
   
    </div>

{/* 
    <OverlayTrigger trigger="click" placement="top" overlay={popover}>
      <Button variant="secondary">Popover on top</Button>
    </OverlayTrigger> */}

    <div style={{height:'15vh'}}>
        <div className='search-box'>
    <i className="bi bi-search pe-2"></i><input className='search-input' placeholder='search' value={search} name="search" onChange={(e)=>setSearchValue(e.target.value)} />
    </div>
    <p className='total-chat-txt pt-2 pe-2 ps-2'>{searchResults && searchResults.length} saved chats</p>

    </div>

    <div style={{height:'60vh'}} className='history-section pe-2'>
    

{history && history.map((chats)=>(
   <div onClick={()=>selectValue(chats.session_id)} style={{cursor:'pointer'}}>
   <span className='date-txt p-2'>{convertUtcToSriLankanTime(chats.save_date_time)}</span>

   <div className='d-flex p-2 history-box'>
       <div className='flex-2 history'>
      {chats.chat_body}
       </div>

       <div className='flex-1'>
       <i className="bi bi-bookmark-fill" style={{color:'rgba(35, 160, 120, 1)'}}></i>
       </div>
   </div>
</div>
))     }




        {/* <div>
            <span className='date-txt p-2'>11 Jan 2023</span>

            <div className='d-flex p-2 history-box'>
                <div className='flex-2 history'>
                What are the accounting methods are using to calculate FD rates in 2023 schema at NTB bank ?
                </div>

                <div className='flex-1'>
                <i className="bi bi-bookmark-fill" style={{color:'rgba(35, 160, 120, 1)'}}></i>
                </div>
            </div>
        </div>


        <div>
            <span className='date-txt p-2'>11 Jan 2023</span>

            <div className='d-flex p-2 history-box'>
                <div className='flex-2 history'>
                What are the accounting methods are using to calculate FD rates in 2023 schema at NTB bank ?
                </div>

                <div className='flex-1'>
                <i className="bi bi-bookmark-fill" style={{color:'rgba(35, 160, 120, 1)'}}></i>
                </div>
            </div>
        </div> */}

    </div>

</nav>
    );
}