import { ProtectedRouteProps } from '../types/types';
import { Header } from './header/header';
import './layout.scss';



export const Layout =({ children }: ProtectedRouteProps) => {


    return(
<div style={{height: "100vh", display: "flex", flexDirection: "column"}} className='slide-up'>
    <Header />
    <div style={{flexGrow: 1, overflowY: "auto"}}>
        {children} {/* This is your content area */}
    </div>
    {/* <div style={{padding: "2rem", textAlign: "center"}}>
        <footer>© 2023 Copyright by spemai. All rights reserved.</footer>
    </div> */}
</div>
    )
}