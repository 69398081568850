import { useEffect, useState } from "react";
import { RightMenuProps } from "../../types/core";
import './document-search-right-bar.scss';
import { filterData, SearchType } from 'filter-data';

export const DocumentSearchRightBar=({isExpanded,closeMenu,searchResults, selectValue, isloading, resetList}:RightMenuProps)=>{

    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndex, setSelectIndex] = useState<number|null>();
    const [result, setresult] = useState(searchResults);
    const [search, setSearchValue] = useState('');

 const onResultSelect=(result:any,index:number)=>{
    setSelectIndex(index)
    selectValue(result)
 }   


 
 const searchConditions = [
    {
      key: 'filename',
      value: search,
      type: SearchType.LK,
    }
  ];

  useEffect(()=>{
    setresult(searchResults)
    setSelectIndex(null)
  },[searchResults])


  useEffect(()=>{
    if(search){
        setresult(filterData(searchResults?searchResults:[], searchConditions))
    }else{
        setresult(searchResults)
    }
   
},[search])

const ProgressBar = (similarity:any) => {
    // Convert similarity to a percentage
    const widthPercentage = similarity * 100;
    
     // Define start and end intensities for HSL
  // Adjust these values to change the intensity range
  const startIntensity = 70; // Less intense
  const endIntensity = 30; // More intense
  
  // Generate start and end colors for the gradient
  const startColor = `hsl(120, 100%, ${startIntensity}%)`; // Less intense green
  const endColor = `hsl(120, 100%, ${endIntensity}%)`; // More intense green
  
  // Generate linear gradient for background
  const gradient = `linear-gradient(to right, ${startColor}, ${endColor})`;
  
  return (
    <div className="progress" style={{ height: '5px', backgroundColor: '#eee' }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{
          width: `${widthPercentage}%`,
          backgroundImage: gradient // Apply gradient as background
        }}
      ></div>
    </div>
    );
  };

return (
<nav className={`history-sidebar ${isExpanded? 'history-sidebar-open' : ''}`}>

    <div className='chat-header d-flex align-items-center justify-content-between p-2'>
    <i  onClick={closeMenu} className="bi bi-chevron-right me-2 menu-options-desktop"></i> <span className='history-heading'>Search Result</span> <span className="clear" style={{cursor:"pointer"}} onClick={resetList}><i className="bi bi-x"></i>clear </span>
    </div>

    <div style={{height:"10vh"}}>
        <div className='search-box'>
    <i className="bi bi-search pe-2"></i><input className='search-input' placeholder='search'  value={search} name="search" onChange={(e)=>setSearchValue(e.target.value)}/>
    </div>
    </div>


    <div style={{height:'68vh'}} className='history-section pe-2 ps-2'>


{!isloading && result && result.map((results:any,index:number)=>(
    <div style={{cursor:"pointer"}} className={`${index==selectedIndex?'pdf-selected-box':'pdf-box'} mb-2`} onClick={()=>onResultSelect(results,index)}>
    <p className={`${index==selectedIndex?'selected-pdf-name':'pdf-name'}`}>{results.filename}</p>
    <div className="d-flex justify-content-between">
        <p className={`${index==selectedIndex?'selected-pdf-index':'pdf-index'}`}>Similarity: {(results.similarity*100).toFixed(2)}%</p>
        <p className={`${index==selectedIndex?'selected-pdf-index':'pdf-index'}`}>Relevance: {(results.relevance*100).toFixed(2)}%</p>
        
    </div>

    {/* <div className="progress" style={{height:'5px'}}>
<div className="progress-bar bg-success" role="progressbar" style={{width: `${results.similarity*100}%`}}></div>
</div> */}
{ProgressBar(results.similarity)}
</div>
))}


{isloading && <div className="list">
  <div className="list-item"></div>
  <div className="list-item"></div>
  <div className="list-item"></div>
  <div className="list-item"></div>
  <div className="list-item"></div>
  <div className="list-item"></div>

</div>}


{!isloading && searchResults && searchResults.length==0 && <div className="text-center">
    No results found
</div>
}




     

    </div>

</nav>
    );

}