export const convertUtcToSriLankanTime = (utcTime:any) => {
    const utcDate = new Date(utcTime);
  
    const options = {
      timeZone: 'Asia/Colombo', // Set the time zone to Sri Lanka
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Use 12-hour format
    } as Intl.DateTimeFormatOptions;
  
    const sriLankanTime = utcDate.toLocaleString('en-US', options);
  
    // Add 5 hours and 30 minutes to the time
    const sriLankanTimeWithOffset = new Date(utcDate.getTime() + (5 * 60 + 30) * 60 * 1000);
  
    return sriLankanTimeWithOffset.toLocaleString('en-US', options);
  };
  