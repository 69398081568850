import { useState } from 'react';
import { LeftMenuProps } from '../../types/core';
import './document-search-left-bar.scss';


export const DocumentSearchLeftBar=({isExpanded,closeMenu,historyList,selectedKB,goBack}:LeftMenuProps)=>{

    const [documentSearch,setDocumentSearch] = useState<number>();

    const DocumentSearchClass=()=>{

    }


    return(
        <nav className={`channel-sidebar ${isExpanded? 'channel-sidebar-open' : ''}`}>

        <div onClick={closeMenu} className='chat-header d-flex justify-content-center align-items-center spemai-heading'>
        <i className="bi bi-chevron-left me-2 menu-options-desktop"></i> <i onClick={goBack} style={{cursor:'pointer'}}  className="bi bi-chevron-left me-2 menu-options-mobile"></i> <span className='spemai-heading'>Document Search</span>
        </div>
        <div style={{height:'6vh'}} className='search-history ps-3 pt-2'>Search History</div>
        <div style={{height:'74vh'}} className='channel-section pt-2 ps-3 pe-2 pb-2'>
    


{historyList && historyList.map((history:any,id:number)=>(
      <div onClick={()=>{setDocumentSearch(id);selectedKB(history)}} className={`doc-history-box mb-2 ${documentSearch==id?'':'channel'}`}>
            {history.search_term}
      </div>
))}

{historyList && historyList.length==0 && <div>
    No History Found
</div>}
    
            
        </div>
    
    </nav>
    )

}