import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createUserId } from '../../services/chat.service';

export const RedirectPage=()=> {
  const { id } = useParams();
  const navigate = useNavigate();
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      fetchData();
    }
  }, [id, navigate]); // Added navigate if it's used elsewhere and can change

  const fetchData = async () => {
    try {
      if (!id) {
        console.log('No id available');
        return;
      }

      const userId = localStorage.getItem('user_id');
      const uuid = localStorage.getItem('uuid');

      if (userId && uuid === id) {
        navigate('/');
      } else {
        await createUser();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const createUser = async () => {
    try {
      const response = await createUserId(id?id:'');

      if (response.message=="User created successfully") {
        localStorage.setItem('uuid', id?id:'');
        localStorage.setItem('user_id', response.data['user_id']);
        navigate('/');
      } else {
        navigate('/unauth');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      navigate('/unauth');
    }
  };

  return <div />;
}

