import {
  AgentapiCall,
  ChatHistoryApiCall,
  CustomAuthorizedApiCall,
  apiCall,
} from '../utils/api_util.service';

export const createUserId = async (uuid: string) => {
  try {
    const data = await apiCall('v1/chat_window/create/user_id/?uuid=' + uuid);
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

export const getAgent = async (uuid: string) => {
  try {
    const data = await AgentapiCall('defaultChat/knowledgeBases/?uuid=' + uuid);
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    console.log('error');
    return error;
  }
};

export const getSessionByAgent = async (
  uuid: string,
  user_id: string,
  knowledge_base_id: number
) => {
  try {
    const data = await apiCall(
      'v1/chat_window/get/session_id/?uuid=' +
        uuid +
        '&user_id=' +
        user_id +
        '&kb_id=' +
        knowledge_base_id
    );
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

export const createChat = async (chatdata: any) => {
  try {
    const data = await CustomAuthorizedApiCall(
      'v1/chat_window/ask/',
      'POST',
      chatdata
    );
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

export const getChatHistory = async (session_id: any) => {
  try {
    const data = await ChatHistoryApiCall(
      'v1/chat_window/chat/history/?session_id=' + session_id
    );
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

export const getStartNewChat = async (
  uuid: string,
  user_id: string,
  knowledge_base_id: any
) => {
  try {
    const data = await apiCall(
      'v1/chat_window/create/session_id/?uuid=' +
        uuid +
        '&user_id=' +
        user_id +
        '&kb_id=' +
        knowledge_base_id
    );
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

export const saveChatSession = async (chatdata: any) => {
  try {
    const data = await CustomAuthorizedApiCall(
      'v1/chat_window/ask/',
      'POST',
      chatdata
    );
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

export const getPreviousChats = async (uuid: string, user_id: string) => {
  try {
    const data = await ChatHistoryApiCall(
      'v1/chat_window/user/saved-session/?uuid=' + uuid + '&user_id=' + user_id
    );
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

export const SaveChat = async (chatdata: any) => {
  try {
    const data = await ChatHistoryApiCall(
      'v1/chat_window/user/save-session/',
      'POST',
      chatdata
    );
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};
