import { useState } from 'react';
import './channel-left-bar.scss';
import SelectedChannel from '../../assets/selected-channel.svg';
import Channel from '../../assets/channel.svg';
import { LeftMenuProps, agentTypes } from '../../types/core';
import { channel } from 'diagnostics_channel';

export const ChannelLeftBar=({isExpanded,closeMenu,agentList, selectedKB, isloading}:LeftMenuProps)=>{

    const [selectedIndex,setSelectedIndex]= useState<number>();

    const valueSelected=(index:number)=>{
        setSelectedIndex(index)
    }
return (
<nav className={`channel-sidebar ${isExpanded? 'channel-sidebar-open' : ''}`}>

    <div onClick={closeMenu} className='chat-header d-flex justify-content-center align-items-center spemai-heading'>
    <i className="bi bi-chevron-left me-2 menu-options-desktop"></i> <span className='spemai-heading'>Spemai AI</span>
    </div>
    <div style={{height:'5vh'}} className='d-flex justify-content-between pt-2 pb-2 pe-2 ps-3'>
        <div className='channel-header'>
            Channels
        </div>
        <div>
        <a target='_blank' href='https://enterprise-dev.spemai.com/'><i className="bi bi-plus-circle-fill" style={{color:"rgba(50, 133, 101, 1)"}}></i></a>
        </div>
</div>


    <div style={{height:'75vh'}} className='channel-section p-2'>

{!isloading && agentList && agentList.map((agents:agentTypes,index:any)=>(
 <div style={{cursor:'pointer'}} className={`${selectedIndex==index?'selected-channel':'channel'}`} onClick={()=>{selectedKB(agents);valueSelected(index)}}>
 <img className='pe-1' src={selectedIndex==index?SelectedChannel:Channel}/> <span>{agents.knowledgeBaseName}</span>
</div>
))}


{isloading && <div className="list">
  <div className="list-item"></div>
  <div className="list-item"></div>
  <div className="list-item"></div>
  <div className="list-item"></div>
  <div className="list-item"></div>
  <div className="list-item"></div>

</div>}

        {/* <div className='channel'>
        <img className='pe-1' src={Channel}/> <span>Marketing Materials and more information</span>
        </div> */}


        
    </div>

</nav>
);
}