import axios from 'axios';
import {
  base_url,
  base_url_history,
  kb_base_url,
} from '../environment/environment';
// import { base64Encoder, base64Decoder, aes_encrypt, aes_decrypt } from './encryption';

const api = axios.create({
  baseURL: base_url, //process.env.REACT_APP_BASE_URL,
});

const historyapi = axios.create({
  baseURL: base_url_history, //process.env.REACT_APP_BASE_URL,
});

const agent_api = axios.create({
  baseURL: kb_base_url, //process.env.REACT_APP_BASE_URL,
});

export const apiCall = async (url: any, method = 'GET', data = null) => {
  //   const token = localStorage.getItem('token');
  //   if(data){
  //     data=encrypt(data)
  //   }

  const currentTimestamp = new Date().getTime();
  const input = { uuid: localStorage.getItem('uuid') };
  var jsonObject = {"uuid":input.uuid, "ts": currentTimestamp};

  // Convert JSON object to a string
  var jsonString = JSON.stringify(jsonObject);
  const headers = {
    'Content-Type': 'text/plain',
    Authorization: jsonString,
  };

  try {
    const response = await api.request({
      url,
      method,
      data,
      headers,
    });

    return response.data;

    // return decrypt(response.data);
  } catch (error) {
    // Handle error here
  }
};

export const apiCall2 = async (url: any, method = 'GET', data = null) => {
  //   const token = localStorage.getItem('token');
  //   if(data){
  //     data=encrypt(data)
  //   }

  const currentTimestamp = new Date().getTime();
  const input = { uuid: localStorage.getItem('uuid') };

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `{"uuid":"${input.uuid}", "ts": ${currentTimestamp}}`,
  };

  try {
    const response = await api.request({
      url,
      method,
      data,
      headers,
    });

    return response.data;

    // return decrypt(response.data);
  } catch (error) {
    // Handle error here
  }
};

export const authorizedapiCall = async (
  url: any,
  method = 'GET',
  data = null
) => {
  const token =
    (await sessionStorage.getItem('access_token')) ||
    localStorage.getItem('access_token');
  // if(data){
  //   data=encrypt(data)
  // }

  const headers = {
    'Content-Type': 'application/json', //'text/plain',
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await api.request({
      url,
      method,
      data,
      headers,
    });

    return response.data;

    // return decrypt(response.data);
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // Navigate to login if a 401 Unauthorized error is caught
      window.location.href = '/login';
    }
  }
};

export const CustomAuthorizedApiCall = async (
  url: any,
  method = 'GET',
  data = null
) => {
  // const token = sessionStorage.getItem('access_token');
  // if(data){
  //   data=encrypt(data)
  // }

  // const headers = {
  //   'Content-Type': 'application/json',//'text/plain',
  //   Authorization: `${token_string}`,
  // };

  try {
    const response = await api.request({
      url,
      method,
      data,
    });

    return response.data;

    // return decrypt(response.data);
  } catch (error) {
    // Handle error here
  }
};

export const ChatHistoryApiCall = async (
  url: any,
  method = 'GET',
  data = null
) => {
  // const token = sessionStorage.getItem('access_token');
  // if(data){
  //   data=encrypt(data)
  // }

  // const headers = {
  //   'Content-Type': 'application/json',//'text/plain',
  //   Authorization: `${token_string}`,
  // };

  try {
    const response = await historyapi.request({
      url,
      method,
      data,
    });

    return response.data;

    // return decrypt(response.data);
  } catch (error) {
    // Handle error here
  }
};

export const AgentapiCall = async (url: any, method = 'GET', data = null) => {
  //   const token = localStorage.getItem('token');
  //   if(data){
  //     data=encrypt(data)
  //   }

  const currentTimestamp = new Date().getTime();
  const input = { uuid: localStorage.getItem('uuid') };
  var jsonObject = {"uuid":input.uuid, "ts": currentTimestamp};

  // Convert JSON object to a string
  var jsonString = JSON.stringify(jsonObject);
  const headers = {
    'Content-Type': 'text/plain',
    Authorization: jsonString,
  };

  try {
    const response = await agent_api.request({
      url,
      method,
      data,
      headers,
    });

    return response.data;

    // return decrypt(response.data);
  } catch (error) {
    // Handle error here
    console.log(error);
  }
};
