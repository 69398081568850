import ImageComponent from '../../core/Image/Image';
import './header.scss';
import logo from '../../assets/logo2.png'

export const Header = () => {
    return (
        <div className='header'>
            <div className='h-100 d-flex align-items-center justify-content-between'>
                <ImageComponent className={"logo p-3"} alt='logo' src={logo} />
                <div className='me-5'>
                    <i className="bi bi-bell" style={{color:"white"}}></i>
                </div>
            </div>
        </div>
    )
}