import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { RedirectPage } from './pages/redirect-page/redirect-page';
import { Layout } from './layouts/layout';
import { ProtectedRouteProps } from './types/types';
import { ChatPage } from './pages/chat-page/chat-page';
import { UnauthPage } from './pages/unauth-page/unauth-page';


const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  if (!localStorage.getItem('uuid')) {
    // User is not authenticated, redirect to login page
    return <Navigate to="/unauth" />;
  }
  return  <Layout>{children}</Layout>;
};


function App() {
  return (
    <div style={{height:'100vh'}}>
        <BrowserRouter>
            <Routes>
              <Route path="/:id" element={<RedirectPage />} />
              <Route path="/unauth" element={<UnauthPage />} />
               
    <Route
            path="/"
            element={
              <ProtectedRoute>
                 

                <ChatPage />
             
              </ProtectedRoute>
            }
          /> 

            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
