import { ChannelLeftBar } from '../../components/channel-left-bar/channel-left-bar';
import { HistoryRightBar } from '../../components/history-right-bar/history-right-bar';
import './chat-page.scss';
import submit from '../../assets/submit.svg';
import { useEffect, useRef, useState } from 'react';
import {
  SaveChat,
  createChat,
  getAgent,
  getChatHistory,
  getPreviousChats,
  getSessionByAgent,
  getStartNewChat,
} from '../../services/chat.service';
import { ApiAttributes } from '../../types/types';
import { agentTypes } from '../../types/core';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { DocumentSearchRightBar } from '../../components/document-search-right-bar/document-search-right-bar';
import { DocumentSearchLeftBar } from '../../components/document-search-left-bar/document-search-left-bar';
import { useFormik } from 'formik';
import {
  getDocumentHistory,
  getDocumentSearch,
} from '../../services/document-chat.service';

import loading from '../../assets/loading.gif';
import { TypeAnimation } from 'react-type-animation';
import { convertUtcToSriLankanTime } from '../../utils/date_time';
import { Footer } from '../../layouts/footer/footer';
import doc from '../../assets/doc.png';
import info from '../../assets/info.png';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import document from '../../assets/document.png';
import { ChatMessages } from '../../components/chat-messages/chat-messages';
// import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   './pdf.worker.min.js',
//   import.meta.url,
// ).toString();

export const ChatPage = () => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const iframeRef = useRef<any>(null);
  const interval = useRef<any>();

  const userIdLocal: string = localStorage.getItem('user_id') ?? '';
  const uuIdIdLocal: string = localStorage.getItem('uuid') ?? '';

  const [isDocumentSearch, setIsDocumentSearch] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>();
  const [documentSearchResult, setdDocumentSearchResult] = useState<any>([]);
  const [documentHistory, setdDocumentHistory] = useState<any>([]);

  const [isLeftOpen, setIsLeftOpen] = useState(false);
  const [isRightOpen, setIsRightOpen] = useState(false);

  const [agentList, setAgentList] = useState<any>([]);
  const [agentSelected, setAgentSelected] = useState<agentTypes | null>();

  const [sessionId, setSessionId] = useState<string>();
  const [chatId, setChatId] = useState('');
  const [chatData, setChatData] = useState([]);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [isLoading, setIsLoading] = useState(false);

  const [isagentLoading, setIsAgentLoading] = useState(false);

  const [isdocumentLoading, setIsDocumentLoading] = useState(false);

  const [isFileLoading, setIsFileLoading] = useState(false);

  const [previouschats, setPreviousChats] = useState<any>([]);
  const [average, setAverage] = useState('LOW');
  const [similarity, setSimilarity] = useState('LOW');

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPageNumber(1);
    setNumPages(numPages);
    setIsFileLoading(false);
  }

  const CloseLeftMenu = () => {
    setIsLeftOpen(false);
  };

  const CloseRightMenu = () => {
    setIsRightOpen(false);
  };

  const clearCheckingInterval = () => {
    clearInterval(interval.current);
  };

  const onIframeLoaded = () => {
    clearCheckingInterval();
    setIsFileLoading(true);
  };

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) =>
      Math.min(prevPageNumber + 1, numPages ? numPages : 0)
    );
  };

  useEffect(() => {
    interval.current = setInterval(() => {
      try {
        // google docs page is blank (204), hence we need to reload the iframe.
        if (iframeRef.current.contentWindow.document.body.innerHTML === '') {
          iframeRef.current.src = selectedDocument.file_path;
        }
      } catch (e) {
        // google docs page is being loaded, but will throw CORS error.
        // it mean that the page won't be blank and we can remove the checking interval.
        onIframeLoaded();
      }
    }, 5000); // 4000ms is reasonable time to load 2MB document

    return clearCheckingInterval;
  }, [selectedDocument]);

  useEffect(() => {
    setIsFileLoading(true);
    setPageNumber(1);
  }, [selectedDocument]);

  useEffect(() => {
    getAgentData();
    retrievePreviousChats();
    //DocumentHistory()
  }, []);

  useEffect(() => {
    if (agentSelected) {
      createSessionID(agentSelected.knowledgeBaseId);
    }
  }, [agentSelected]);

  useEffect(() => {
    if (sessionId) {
      DocumentHistory();
    }
  }, [sessionId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatData]);

  const getAgentData = async () => {
    setIsAgentLoading(true);
    try {
      console.log('visited');
      let agent_response: ApiAttributes = await getAgent(uuIdIdLocal);
      setIsAgentLoading(false);

      if (agent_response.code == 200) {
        if (agent_response.details) {
          setAgentList(agent_response.details);
        } else {
          console.log('Data is not available or not in the expected format.');
        }
      } else {
        console.log(
          'Data status is not 100. Handle the status code appropriately.'
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const createSessionID = async (agentId: any) => {
    try {
      let response: any = await getSessionByAgent(
        uuIdIdLocal,
        userIdLocal,
        agentId
      );
      console.log('session id', response);
      if (response.data.session_id) {
        setSessionId(response.data.session_id);
        getChatHistoryByAgent(response.data.session_id);
      } else {
        console.log('Data is not available');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const getChatHistoryByAgent = async (session_id: string) => {
    try {
      let response = await getChatHistory(session_id);
      let data2 = response.data;

      if (response) {
        if (response['data']) {
          // setResponse(false)
          let processedData: any = response['data']['history'].reverse();
          const newArray = processedData.map((obj: any) => ({
            ...obj,
            data_animation: false,
          }));

          setChatData(newArray);

          setChatId(response['data']['id']);
          // setResponse(false)
        } else {
          console.log('Data is not available or not in the expected format.');
          // setResponse(false)
        }
      } else {
        console.log(
          'Data status is not 100. Handle the status code appropriately.'
        );
        // setResponse(false)
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setChatData([]);
      setChatId('');
      // setIsGLoabelLoading(false)
      // setResponse(false)
    }
  };

  const formik = useFormik({
    initialValues: {
      session_id: sessionId,
      question: '',
      uuid: uuIdIdLocal,
      search_term: '',
      similarity: 'LOW', //LOW,AVERAGE,HIGH
      relevance: 'LOW', //LOW,AVERAGE,HIGH
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSelectedDocument('');
      if (isDocumentSearch) {
        setIsDocumentLoading(true);
        // let doc_json={
        //     "uuid":uuIdIdLocal,
        //     "session_id":sessionId,
        //     "search_term":values.search_term,
        //     "similarity":"AVERAGE",//LOW,AVERAGE,HIGH
        //     "relevance":"AVERAGE"//LOW,AVERAGE,HIGH
        // }
        let doc_json = {
          uuid: uuIdIdLocal,
          session_id: sessionId,
          search_term: values.search_term,
          similarity: similarity, //LOW,AVERAGE,HIGH
          relevance: average, //LOW,AVERAGE,HIGH
        };
        values.search_term = '';
        let document_response: any = await getDocumentSearch(doc_json);
        setdDocumentSearchResult(document_response.results);
        setIsDocumentLoading(false);
        DocumentHistory();
      } else {
        handleSubmit(values, setSubmitting);
      }
    },
  });

  const DocumentHistory = async () => {
    const document_history_response = await getDocumentHistory(sessionId);

    if (document_history_response && document_history_response) {
      setdDocumentHistory(document_history_response.data.history);
    }
  };

  const handleSubmit = async (data: any, setSubmitting: any) => {
    // setResponse(true)
    // setAnimation(true)
    // handleReset()
    let tempMessages = [...chatData];
    if (!sessionId) {
      console.log('error');
      return;
    }

    data['uuid'] = uuIdIdLocal;
    data['session_id'] = sessionId;
    data['question'] = data['search_term'];

    tempMessages.push({
      [chatId]: {
        question: data['search_term'],
        // "question_time": convertSriLankanTimeToUtc(new Date()),
        question_time: new Date().getTime() - (5 * 60 + 30) * 60 * 1000,
        answer: '',
        answer_time: '',
        data_animation: true,
      },
      data_animation: true,
    } as never);
    setChatData([...tempMessages]);
    setIsLoading(true);

    formik.resetForm();
    try {
      const response = await createChat(data);

      setSubmitting(false);
      setIsLoading(false);

      if (response['message'] == 'Answer successfully initiated') {
        // setResponse(false)
        console.log("visisted")
        tempMessages[tempMessages.length - 1][chatId]['answer'] = response.data[
          'answer'
        ] as never;
        // tempMessages[tempMessages.length - 1][chatId]["answer_time"] = convertSriLankanTimeToUtc(new Date()) as never
        tempMessages[tempMessages.length - 1][chatId]['answer_time'] = new Date(
          new Date().getTime() - (5 * 60 + 30) * 60 * 1000
        ) as never;
        console.log("visisted",tempMessages)
        setChatData([...tempMessages]);
      } else {
        setSubmitting(false);
        // setResponse(false)
        tempMessages[tempMessages.length - 1][chatId]['answer'] =
          "sorry I can't understand what you say" as never;
        // tempMessages[tempMessages.length - 1][chatId]["answer_time"] = convertSriLankanTimeToUtc(new Date()) as never
        tempMessages[tempMessages.length - 1][chatId]['answer_time'] = new Date(
          new Date().getTime() - (5 * 60 + 30) * 60 * 1000
        ) as never;
        setChatData([...tempMessages]);

        formik.resetForm();
      }
    } catch (error) {
      // setResponse(false)
      setIsLoading(false);
      setSubmitting(false);
      tempMessages[tempMessages.length - 1][chatId]['answer'] =
        "sorry I can't understand what you say" as never;
      // tempMessages[tempMessages.length - 1][chatId]["answer_time"] = convertSriLankanTimeToUtc(new Date()) as never
      tempMessages[tempMessages.length - 1][chatId]['answer_time'] = new Date(
        new Date().getTime() - (5 * 60 + 30) * 60 * 1000
      ) as never;
      setChatData([...tempMessages]);
      formik.resetForm();
    }
  };

  const startNewChat = async () => {
    setChatData([]);
    // setFirstFunctionCall(true)
    try {
      let response = await getStartNewChat(
        uuIdIdLocal,
        userIdLocal,
        agentSelected?.knowledgeBaseId
      );

      let data2 = response.data;

      if (response.code == 200) {
        if (data2) {
          setSessionId(data2?.session_id);
        } else {
          console.log('Data is not available or not in the expected format.');
        }
      } else {
        console.log(
          'Data status is not 100. Handle the status code appropriately.'
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const retrievePreviousChats = async () => {
    try {
      let response = await getPreviousChats(uuIdIdLocal, userIdLocal);
      let data2 = response.data;

      if (response) {
        if (data2) {
          // setSaveChatHead(data2["data"])
          setPreviousChats(data2);
        } else {
          console.log('Data is not available or not in the expected format.');
        }
      } else {
        console.log(
          'Data status is not 100. Handle the status code appropriately.'
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleSavechat = async () => {
    let data: any = {
      uuid: uuIdIdLocal,
      user_id: userIdLocal,
      session_id: sessionId,
      is_save: true,
    };

    try {
      const response = await SaveChat(data);

      if (response.message == 'Sessions update successfully') {
        retrievePreviousChats();
      } else {
        // toast.error(response.message)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const HandleChatHistoryClick = (id: string) => {
    setSessionId(id);
    setChatData([]);
    setAgentSelected(null);
    getChatHistoryByAgent(id);
  };

  const popover = (
    <Popover id="popover-basic" style={{ minWidth: '200px' }}>
      <Popover.Body>
        <label className="filter-label pb-2">Similarity</label>
        <select
          value={similarity}
          onChange={(e) => setSimilarity(e.target.value)}
          name="similarity"
          className="form-control select-control"
        >
          <option value={'LOW'}>Low</option>
          <option value={'AVERAGE'}>Average</option>
          <option value={'HIGH'}>High</option>
        </select>

        <label className="filter-label pb-2">Relevance</label>
        <select
          value={average}
          onChange={(e) => setAverage(e.target.value)}
          name="average"
          className="form-control select-control"
        >
          <option value={'LOW'}>Low</option>
          <option value={'AVERAGE'}>Average</option>
          <option value={'HIGH'}>High</option>
        </select>
      </Popover.Body>
    </Popover>
  );

  const updateResult = (value: any) => {
    setdDocumentSearchResult(value.results);
  };
  return (
    <div className="d-flex justify-content-between">
      {!isDocumentSearch && (
        <ChannelLeftBar
          isloading={isagentLoading}
          selectedKB={setAgentSelected}
          isExpanded={isLeftOpen}
          closeMenu={CloseLeftMenu}
          agentList={agentList}
        />
      )}
      {isDocumentSearch && (
        <DocumentSearchLeftBar
          goBack={() => {
            setIsDocumentSearch(false);
            setSelectedDocument('');
          }}
          isExpanded={isLeftOpen}
          closeMenu={CloseLeftMenu}
          historyList={documentHistory}
          selectedKB={updateResult}
        />
      )}

      <div className="presentation">
        {/* Header view for desktop */}
        <div className="menu-options-mobile justify-content-between chat-header align-items-center">
          {!isDocumentSearch && (
            <>
              <div>
                <p className="chat-heading">
                  {agentSelected?.knowledgeBaseName}
                </p>
              </div>

              <div className=" d-flex">
                <div
                  className="save-chat m-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSavechat()}
                >
                  <i className="bi bi-bookmark pe-1"></i> <span>Save Chat</span>
                </div>
                <div
                  className="new-chat m-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => startNewChat()}
                >
                  <i className="bi bi-chat pe-1"></i>{' '}
                  <span>Open a new chat</span>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Header view for mobile */}
        <div className="menu-options-desktop justify-content-around">
          {!isDocumentSearch && (
            <>
              <i
                className="pt-2 bi bi-list pe-1"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsLeftOpen(true);
                }}
              ></i>
              <div className="d-flex align-items-start">
                <p className="chat-heading">
                  {agentSelected?.knowledgeBaseName}
                </p>
              </div>

              <i
                className="pt-2 bi bi-bookmark pe-1"
                onClick={() => handleSavechat()}
                style={{ cursor: 'pointer' }}
              ></i>
              <i
                onClick={() => startNewChat()}
                className="pt-2 bi bi-chat pe-1"
                style={{ cursor: 'pointer' }}
              ></i>
              <i
                onClick={() => setIsRightOpen(true)}
                className="pt-2 bi bi-clock-history"
                style={{ cursor: 'pointer' }}
              ></i>
            </>
          )}

          {isDocumentSearch && (
            <>
              <i
                className="menu-options pt-2 bi bi-list pe-1"
                onClick={() => {
                  setIsLeftOpen(true);
                }}
              ></i>

              <i
                onClick={() => setIsRightOpen(true)}
                className="menu-options pt-2 bi bi-clock-history"
              ></i>
            </>
          )}
        </div>

        <div className="chat-section">
          <div className="chat-section2 ps-4 pe-5 pt-2 pb-2">
            {!selectedDocument && chatData.length == 0 && !isDocumentSearch && (
              <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                <p className="main-heading text-center">Welcome to Spemai AI</p>
                <p className="main-sub-heading text-center">
                  Easy way to generate and find instructed data from easy few
                  steps.
                </p>

                {agentSelected && (
                  <div className="d-flex justify-content-around">
                    <div
                      className="main-option me-3 slide-up"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsDocumentSearch(true);
                        setChatData([]);
                      }}
                    >
                      <img src={doc} className="me-1" /> Find Documents{' '}
                      <span
                        style={{
                          background: 'red',
                          color: 'white',
                          borderRadius: '8px',
                          padding: '4px',
                        }}
                      >
                        Beta
                      </span>
                    </div>

                    <div
                      className="main-option slide-up"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsDocumentSearch(false);
                      }}
                    >
                      <img src={info} className="me-1" /> Find Information
                    </div>
                  </div>
                )}

                {!agentSelected && (
                  <div className="main-sub-heading text-center">
                    {' '}
                    Select a channel to continue
                  </div>
                )}
              </div>
            )}

            {!selectedDocument && chatData.length == 0 && isDocumentSearch && (
              <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                <img src={document} />
                <div className="document-header mt-3">
                  Find your best <br /> matching document
                </div>
              </div>
            )}

            {selectedDocument && !isFileLoading && (
              <div className="list">
                <div className="list-item"></div>
                <div className="list-item"></div>
                <div className="list-item"></div>
                <div className="list-item"></div>
                <div className="list-item"></div>
                <div className="list-item"></div>
              </div>
            )}

            {/* For s3 bucket pdf's */}
            {
              <div>
                {selectedDocument &&
                  selectedDocument.file_path &&
                  selectedDocument.file_path.includes(
                    'https://spemai-chat-agent.s3.amazonaws.com'
                  ) && (
                    <iframe
                      ref={iframeRef}
                      className="selected-cv"
                      src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
                        selectedDocument.file_path
                      )}`}
                      onLoad={onIframeLoaded}
                      title="Selected CV"
                    />
                  )}
                        
              </div>
            }

            {/* For google storage pdf's */}
            {
              <div>
                {selectedDocument &&
                  selectedDocument.file_path &&
                  (selectedDocument.file_path.includes(
                    'https://storage.cloud.google.com'
                  )) && (
                    <iframe
                      ref={iframeRef}
                      className="selected-cv"
                      src={selectedDocument.file_path}
                      onLoad={onIframeLoaded}
                      title="Selected CV"
                    />
                  )}
                        
              </div>
            }

            {/* Chat messages  */}

            {!isDocumentSearch && (
              <>
                <ChatMessages chatData={chatData} chatId={chatId} />
                <div ref={messagesEndRef} />
              </>
            )}

            {isLoading ? (
              <div className="row">
                <div className="col-12 mt-2">
                  <div className="typing">
                    <img style={{ width: '50px' }} src={loading} alt="..." />
                  </div>
                </div>
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </div>

        {sessionId && (
          <div className="bottom mt-auto p-2 ">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div
                  className={`option-btn ${
                    !isDocumentSearch ? 'option-selected' : ''
                  }`}
                >
                  <span
                    className="p-2 option-txt"
                    onClick={() => {
                      setIsDocumentSearch(false);
                      setSelectedDocument('');
                    }}
                  >
                    {' '}
                    Find Information
                  </span>
                </div>
                <div
                  className={`option-btn2 ${
                    isDocumentSearch ? 'option-selected' : ''
                  } `}
                  onClick={() => {
                    setIsDocumentSearch(true);
                    setChatData([]);
                  }}
                >
                  <span className="p-2 option-txt"> Document Search</span>
                </div>
              </div>

              {isDocumentSearch && (
                <OverlayTrigger
                  trigger="click"
                  placement="auto"
                  overlay={popover}
                >
                  <div className="result-setting">
                    <i className="bi bi-gear"></i> Result Setting
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="chat-box ">
                <textarea
                  onKeyDown={(e) => {
                    // Check if the Enter key is pressed without the Shift key
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault(); // Prevent the default action to avoid line break in textarea
                      formik.handleSubmit(); // Programmatically submit the form
                    }
                  }}
                  placeholder="Ask me anything....."
                  className={` ps-2 pe-2 pt-1 ${
                    formik.values.search_term.length > 204
                      ? ' chat-input2'
                      : ' chat-input'
                  }`}
                  onChange={formik.handleChange}
                  name="search_term"
                  value={formik.values.search_term}
                ></textarea>

                <div className="d-flex justify-content-between ps-2 pe-2 pb-2">
                  <div>
                    <i
                      className="bi bi-plus-circle-fill"
                      style={{ color: 'rgb(170, 172, 175)' }}
                    ></i>
                  </div>
                  <div>
                    {formik.values.search_term && (
                      <button
                        type="submit"
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                      >
                        <img src={submit} alt="Icon description" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <Footer />
          </div>
        )}
      </div>

      {!isDocumentSearch && (
        <HistoryRightBar
          isExpanded={isRightOpen}
          closeMenu={CloseRightMenu}
          searchResults={previouschats}
          selectValue={(val) => HandleChatHistoryClick(val)}
          resetList={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      )}

      {isDocumentSearch && (
        <DocumentSearchRightBar
          isloading={isdocumentLoading}
          isExpanded={isRightOpen}
          closeMenu={CloseRightMenu}
          searchResults={documentSearchResult}
          selectValue={setSelectedDocument}
          resetList={() => {
            setdDocumentSearchResult([]);
            setSelectedDocument('');
          }}
        />
      )}
    </div>
  );
};
